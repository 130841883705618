<template>
<div>
	<b-jumbotron>
		<b-row>
			<b-col cols="12">
				<b-button variant="primary" class="float-right" v-b-modal.modalAggiungiClient>Nuovo</b-button>
				<b-button variant="secondary" class="float-right mr-2" @click="loadClients">Aggiorna</b-button>
			</b-col>
		</b-row>
		<b-row class="mt-2">
			<b-col cols="12">
				<b-table
					sticky-header
					ref="tableClients"
					responsive
					stacked="lg"
					hover
					:busy="tableClients.busy"
					head-variant="dark"
					table-variant="primary"
					:items="tableClients.items"
					:current-page="tableClients.currentPage"
					:per-page="tableClients.perPage"
					:fields="tableClients.fields">
					<template v-slot:cell(actions)="{item}">
						<b-container>
							<b-row>
								<b-col>
									<b-button size="sm" variant="outline-danger" @click="onDeleteClient(item)" class="mr-1 no-text">
										<b-icon icon="trash"></b-icon>
									</b-button>
								</b-col>
								<b-col>
									<b-form-checkbox v-model="item.enabled" name="check-button-enabled-client" switch size="md" @change="onEnableClient(item)"></b-form-checkbox>
								</b-col>
							</b-row>
						</b-container>
					</template>
				</b-table>
			</b-col>
		</b-row>
		<b-row>
			<b-col lg="3">
				<p>Record Totali: {{tableClients.items.length}}</p>
			</b-col>
			<b-col cols="12">
				<b-pagination v-model="tableClients.currentPage" :total-rows="tableClients.items.length" :per-page="tableClients.perPage" align="right" size="sm"></b-pagination>
			</b-col>
		</b-row>
	</b-jumbotron>
	<b-modal size="xl" ref="modalAggiungiClient" id="modalAggiungiClient" title="Aggiungi Client" @show="onShowModalAggiungiClient" scrollable no-close-on-backdrop>
		<b-row>
			<b-col sm="12" md="6" lg="6" xl="6">
				<b-input v-model="modalAggiungiClient.name" type="text" placeholder="Nome"></b-input>
			</b-col>
			<b-col sm="12" md="6" lg="6" xl="6">
				<b-input v-model="modalAggiungiClient.description" type="text" placeholder="Descrizione"></b-input>
			</b-col>
		</b-row>
		<b-row class="mt-2">
			<b-col sm="12" md="6" lg="6" xl="6">
				<date-picker v-model="modalAggiungiClient.expireDate" value-type="timestamp" :disabled-date="this.$vueDatePickerDisabledDateBeforeToday"></date-picker>
			</b-col>
			<b-col sm="12" md="6" lg="6" xl="6">
				<b-checkbox v-model="modalAggiungiClient.enabled" size="md" name="enableClient" switch>{{modalAggiungiClient.enabled ? 'Abilitato' : 'Disabilitato'}}</b-checkbox>
			</b-col>
		</b-row>
		<b-row class="mt-2">
			<b-col cols="12">
				<b-select v-model="modalAggiungiClient.allowedServices" :options="optionsServiziAnagrafiche" value-field="codiceNazionaleStruttura" text-field="descrizioneStruttura" multiple>
					<b-select-option :value="null" disabled>Seleziona le strutture</b-select-option>
				</b-select>
			</b-col>
		</b-row>
		<template #modal-footer>
			<b-button variant="secondary" @click="onChiudiModalAggiungiClient">Chiudi</b-button>
			<b-button variant="primary" @click="onAggiungiClient">Aggiungi</b-button>
		</template>
	</b-modal>
</div>
</template>

<script>
import DashboardServices from '../services/DashboardServices';
import {DateTime} from 'luxon';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
	name: "Clients",
	components: {
		DatePicker
	},
	data() {
		return {
			tableClients: {
				perPage: 10,
				currentPage: 1,
				busy: false,
				fields: [
					{
						label: 'Nome',
						key: 'name',
						sortable: true
					},
					{
						label: 'Descrizione',
						key: 'description',
						sortable: true
					},
					{
						label: 'Abilitato',
						key: 'enabled',
						formatter: value => value ? 'SI' : 'NO'
					},
					{
						label: 'Scadenza',
						key: 'expireDate',
						formatter: value => DateTime.fromSeconds(value).toFormat('dd/MM/yyyy')
					},
					{ key: 'actions', label: '', thStyle: 'width: 15rem' },
				],
				items: []
			},
			modalAggiungiClient: {
				name: '',
				description: '',
				enabled: true,
				expireDate: null,
				allowedServices: []
			},
			optionsServiziAnagrafiche: []
		};
	},
	mounted() {
		this.loadDefaultData();
	},
	methods: {
		loadDefaultData() {
			this.loadClients();
			this.loadServiziAnagrafiche();
		},
		loadServiziAnagrafiche() {
			let me = this;
			DashboardServices.getServiziAnagrafiche(me.$router)
				.then(response => {
					if (response.error)
						return console.log(response.msg);
					me.optionsServiziAnagrafiche = response.data;
				})
				.catch();
		},
		loadClients() {
			let me = this;
			me.tableClients.busy = true;
			DashboardServices.getClients(me.$router)
				.then(response => {
					if (response.error)
						return console.log(response.msg);
					me.tableClients.items = response.data;
				})
				.catch()
				.finally(() => me.tableClients.busy = false)
		},
		onShowModalAggiungiClient() {
			this.modalAggiungiClient.name = '';
			this.modalAggiungiClient.description = '';
			this.modalAggiungiClient.enabled = true;
		},
		onAggiungiClient() {
			let me = this;
			const jsonData = {
				...me.modalAggiungiClient,
				expireDate: DateTime.fromMillis(me.modalAggiungiClient.expireDate).toSeconds()
			};
			DashboardServices.createClient(me.$router, jsonData)
				.then(response => {
					if (response.error)
						return console.log(response.msg);
					me.onChiudiModalAggiungiClient();
					me.loadDefaultData();
				})
				.catch();
		},
		onChiudiModalAggiungiClient() {
			this.$refs.modalAggiungiClient.hide();
		},
		onDeleteClient(client) {
			let me = this;
			DashboardServices.deleteClient(me.$router, client.id)
				.then(response => {
					if (response.status !== 204)
						return console.log(response.data.msg);
					me.loadClients();
				})
				.catch();
		},
		onEnableClient(item) {
			let me = this;
			DashboardServices.updateClient(me.$router, item.id, item)
				.then(response => {
					if (response.error)
						return console.log(response.msg);
					me.loadClients();
				})
				.catch();
		}
	}
}
</script>

<style scoped>

</style>
